import { MFlexPublicAPI, mFlexConfig } from '@/types';
import { prefixEventName } from '@/helpers/events';
import { PropertyConfiguration } from '@/components/indexTypes';

const getIframeHost = (environment: string) => {
  if (environment === 'local') {
    return 'http://localhost:8080';
  } else if (environment === 'prod') {
    return 'https://meglerpakke.ambita.com';
  } else {
    return 'https://dev-meglerpakke.ambita.com';
  }
};

const setupIframe = (
  element: string,
  environment: string,
  iframePath: string
): Promise<HTMLIFrameElement> => {
  const iframeElement = document.createElement('iframe');

  if (!element) {
    throw new Error('Failed to init iframe due to missing element selector in configuration.');
  }

  iframeElement.src = `${getIframeHost(environment)}/${iframePath}`;
  iframeElement.style.setProperty('border', 'none');
  iframeElement.style.width = '100%';

  return new Promise((resolve) => {
    iframeElement.addEventListener('load', () => {
      resolve(iframeElement);
    });

    document.querySelector(element)?.append(iframeElement);
  });
};

const loadProductList = async (
  element: string,
  config: mFlexConfig,
  query: PropertyConfiguration
): Promise<HTMLIFrameElement> => {
  const iframe = await setupIframe(
    element,
    config.environment,
    'pages/components/product-list.html'
  );

  iframe.contentWindow?.postMessage(
    {
      action: prefixEventName('load-product-list'),
      content: {
        ...config,
        query,
      },
    },
    getIframeHost(config.environment)
  );

  return iframe;
};

const loadSearch = async (
  element: string,
  config: mFlexConfig,
  categories: string
): Promise<HTMLIFrameElement> => {
  const iframe = await setupIframe(element, config.environment, 'pages/components/search.html');

  iframe.style.position = 'absolute';
  iframe.contentWindow?.postMessage(
    {
      action: prefixEventName('load-search'),
      content: {
        ...config,
        categories,
      },
    },
    getIframeHost(config.environment)
  );

  return iframe;
};

const loadReceipt = async (element: string, config: mFlexConfig, orderCollectionId: number) => {
  const iframe = await setupIframe(element, config.environment, 'pages/components/receipt.html');

  iframe.contentWindow?.postMessage(
    {
      action: prefixEventName('load-receipt'),
      content: {
        ...config,
        orderCollectionId,
      },
    },
    getIframeHost(config.environment)
  );

  return iframe;
};

const mFlex: MFlexPublicAPI = {
  _config: null,
  init(config: mFlexConfig) {
    this._config = config;
  },
  productList: {
    async load(element: string, query: PropertyConfiguration) {
      if (mFlex._config) {
        this._iframe = await loadProductList(element, mFlex._config, query);
      } else {
        throw new Error('Components are not initialized');
      }
    },
    confirmOrder() {
      return new Promise((resolve, reject) => {
        if (this._iframe) {
          const iframeHost = mFlex._config?.environment
            ? getIframeHost(mFlex._config?.environment)
            : '';
          this._confirmOrderPromiseResolve = resolve;
          this._confirmOrderPromiseReject = reject;
          this._iframe.contentWindow?.postMessage(
            {
              action: prefixEventName('confirm-order'),
            },
            iframeHost
          );
        } else {
          reject('Product list is not loaded');
        }
      });
    },
    resize(height: number) {
      if (this._iframe) {
        this._iframe.style.height = `${height}px`;
      }
    },
    _iframe: null,
  },
  search: {
    async load(element: string, categories: string) {
      if (mFlex._config) {
        this._iframe = await loadSearch(element, mFlex._config, categories);
      } else {
        throw new Error('Components are not initialized');
      }
    },
    resize(height: number) {
      if (this._iframe) {
        this._iframe.style.height = `${height}px`;
      }
    },
    onSearchResult: null,
    _iframe: null,
  },
  receipt: {
    async load(element: string, orderCollectionId: number) {
      if (mFlex._config) {
        this._iframe = await loadReceipt(element, mFlex._config, orderCollectionId);
      } else {
        throw new Error('Components are not initialized');
      }
    },
    resize(height: number) {
      if (this._iframe) {
        this._iframe.style.height = `${height}px`;
      }
    },
    _iframe: null,
  },
};

const onMessage = (event: Event): void => {
  if (event instanceof MessageEvent) {
    const {
      data: { action, content },
    } = event;

    switch (action) {
      case prefixEventName('resize-search'):
        mFlex.search.resize(content.height);
        break;

      case prefixEventName('resize-product-list'):
        mFlex.productList.resize(content.height);
        break;

      case prefixEventName('resize-receipt'):
        mFlex.receipt.resize(content.height);
        break;

      case prefixEventName('on-search-result'):
        if (mFlex.search.onSearchResult) {
          mFlex.search.onSearchResult(content);
        }
        break;
      case prefixEventName('order-confirmed'):
        if (mFlex.productList._confirmOrderPromiseResolve) {
          mFlex.productList._confirmOrderPromiseResolve(content);
        }
        break;
      case prefixEventName('confirm-order-failed'):
        if (mFlex.productList._confirmOrderPromiseReject) {
          mFlex.productList._confirmOrderPromiseReject(content);
        }
        break;

      default:
        break;
    }
  }
};

window.addEventListener('message', onMessage, false);

window.Ambita = window.Ambita || {};
window.Ambita.mFlex = mFlex;

window.AMBITA_BUILD = window.AMBITA_BUILD || {};
window.AMBITA_BUILD.mFlex = {
  LITE: import.meta.env.VITE_LITE_VERSION || '',
  DESIGN_SYSTEM: import.meta.env.VITE_DESIGN_SYSTEM_VERSION || '',
  BUILD: import.meta.env.VITE_BUILD_VERSION || '',
};
